import React from 'react'
import Header from '../Header'
import Menu from '../Menu'
import UnverifiedStudentTable from './UnverifiedStudentTable'

function UnverifiedStudent() {
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
            <UnverifiedStudentTable/>
      </div>
      </div>
  )
}

export default UnverifiedStudent