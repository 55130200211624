import React, { Component, useState, useEffect } from 'react'
import Header from '../Header';
import Menu from '../Menu';
import 'jquery/dist/jquery.min.js';
// //Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import axios from 'axios';
function SpotRound() {
    const [data, setState] = useState([])

    // State array variable to save and show data
    const fetchData = () => {
        axios.get('/engg-students-partial').then(res => {
            //Storing users detail in state array object
            setState({ data: res.data });

            // console.log("dataAPI", res.data.userList);

        });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 15,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: ['copy', 'csv', 'print', 'excelHtml5', 'pdf'
                        ]
                    }
                );
            },
                1000
            );
        });
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">

                <div className="container p-5">

                    <table id="example" class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                                <th>Application No</th>
                                <th>Student Name</th>
                                <th>Father's Name</th>
                                <th>Mobile No</th>
                                <th>Email-ID</th>
                                <th>Obtain Marks</th>
                                <th>Program</th>
                                <th>Rank</th>
                                <th>Password</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {data && data.map((result, slNo) => {
            return (

                <>
                    {
                        result.payStatus &&
                        <tr>
                            <td>{slNo + 1}</td>
                            <td>{result.applicationNo}</td>
                            <td>{result.user.name}</td>
                            <td>{result.user.country}</td>
                            <td>{result.user.gender}</td>
                            <td>{result.user.mobileNo}</td>
                            <td>{result.user.email}</td>
                            <td>{result.examSlot}</td>
                            <td>{result.user.createdAt}</td>
                            <td>{result.user.password}</td>
                           

                        </tr>
                    }
                </>

            )
        })} */}
                            <tr>
                                <td>1</td>
                                <td>243523454325</td>
                                <td>Mohan Kumar</td>
                                <td>Sohan Kumar </td>
                                <td>7004333981</td>
                                <td>mohan@gmail.com</td>
                                <td>180</td>
                                <td>Engg</td>
                                <td>23345</td>
                                <td>3465342</td>
                                <td><button style={{backgroundColor:'#007bff',color:'white',borderRadius:'20%',border:'#007bff'}}>View</button></td>
                            </tr>

                        </tbody>
                    </table>

                </div>

            </div>
        </div>
    )
}

export default SpotRound
