import React, { useState, useEffect } from 'react'
import Header from '../Header'
import Menu from '../Menu'

function CounsellingMarkey() {
  const [markeyName, setMarkeyName] = useState("")
  const [markeyUrl, setMarkeyUrl] = useState("")
  const [markeyRem, setMarkeyRem] = useState("")
  const [makeydata, setMarkeyData] = useState([])
  // console.log(makeydata)



  const markeyData = async () => {
    fetch('/counselling/markey-data')
      .then(response => response.json())
      .then(data => {
        setMarkeyData(data)


      })
      .catch(error => {
        console.log(error)
      })
  }



  async function markey(event) {
    // event.preventDefault()
    if (!markeyName || !markeyUrl) {
      alert('invalid credentials')
    }
    else {
      const response = await fetch('/counselling/markey-add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          markeyName,
          markeyUrl
        })
      })
      const data = await response.json()
      // console.log(data)
      if (data.message === 'please filled all detail') {
        alert('please filled all detail')
      }
      else if (data.message === 'email already exist') {
        alert('markey already exist')
      }
      else if (data.message === 'markey added successfully') {
        alert('markey added successfully')
        setMarkeyName("")
        setMarkeyUrl("")
        markeyData()
      }
      else {
        alert('error')
      }


      //   console.log(data)
    }

  }
  async function markeyremove(id) {
    // event.preventDefault()
    if (!id) {
      alert('invalid credentials')
    }
    else {
      const response = await fetch('/counselling/markey-remove', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id
        })
      })
      const data = await response.json()
      // console.log(data)
      if (data.message === 'please filled all detail') {
        alert('please filled all detail')
      }
      else if (data.message === 'email already exist') {
        alert('markey already exist')
      }
      else if (data.message === 'markey remove successfully') {
        alert('markey remove successfully')

        markeyData()
      }
      else {
        alert('error')
      }


      //   console.log(data)
    }

  }



  useEffect(() => {
    markeyData()

  }, [])
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
      <div style={{width:'100%',margin:'auto',textAlign:'center',fontWeight:'bold', fontSize:'30px'}}>
        Counselling Portal Marque
      </div>
        <form style={{ padding: '1rem' }}>
          <div className="row">
            <div className="col">
              <input type="text" value={markeyName} onChange={(e) => { setMarkeyName(e.target.value) }} className="form-control" placeholder="Markey Name" />
            </div>
            <div className="col">
              <input type="text" value={markeyUrl} onChange={(e) => { setMarkeyUrl(e.target.value) }} className="form-control" placeholder="Markey URL" />
            </div>
          </div>
        </form>
        <center><button onClick={markey} class="btn btn-success">Add Markey</button></center>
        <div style={{ backgroundColor: 'gray', color: 'white', margin: '2rem', padding: '0.5rem', borderRadius: '8px' }}>Previous Markey</div>
        <div style={{ margin: '2rem' }}>
          <table className="table table-secondary">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Markey Name</th>
                <th scope="col">Markey URL</th>
                <th style={{ textAlign: 'center' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                makeydata ?

                  <>
                    {
                      makeydata.map((e, i) => {
                        return (
                          <>
                            <tr>
                              <th scope="row">{i+1}</th>
                              <td>{e.markeyName}</td>
                              <td>{e.markeyUrl}</td>
                              <td style={{ textAlign: 'center', color: 'red', cursor: 'pointer' }}><i onClick={() => { markeyremove(e._id) }} data-target="#myModal" data-toggle="modal" className='fa fa-trash' /></td>
                            </tr>
                          </>
                        )
                      })
                    }
                  </>
                  : null
              }

            </tbody>
          </table>
        </div>
        <>

          {/* Modal */}
          {/* <div id="myModal" className="modal fade" role="dialog">
            <div className="modal-dialog">
              
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    ×
                  </button>

                </div>
                <div className="modal-body">
                  <p>Are You Sure .</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn  btn-primary"
                    data-dismiss="modal"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </>


      </div>
    </div>
  )
}

export default CounsellingMarkey