import React from 'react'
import { useAppContext } from '../context/appContext'
function Alert() {
    const {alertType,alertText} = useAppContext()
    // console.log(alertType)
  return (
    <div class={`alert alert-${alertType}`} role="alert">
  {alertText}
</div>
  )
}

export default Alert