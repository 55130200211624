import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
function StudentProfile() {
    const [data, setData] = useState()
    console.log("data", data)
    const studentInfo = async (id) => {
        // alert(id)
        fetch(`/counselling/student-profile/${id}`)
            .then(response => response.json())
            .then(data => {
                setData(data.data[0])
            })
            .catch(error => {
                console.log(error)
            })
    }
    const componentRef = useRef();
    const { id } = useParams()
    useEffect(() => {
        studentInfo(id)
    }, [])

    return (
        <>

            <div className='' ref={componentRef}>
                {
                    data &&

                    <AcknowledgeMentCSS>
                        <div className='border border-black text-center' >
                            <center><img className='w-[200px] mb-2' src="https://ucarecdn.com/310f986c-7286-41bf-9bfd-79673ec9eff1/AICEE2023LOGO4.png" alt="logo" /></center>
                            <div style={{ lineHeight: '3px' }}>
                                <p className='text[14px] display-font-sizes-3rem font-weight-bold text-black' style={{ fontSize: '14px' }}>{data.program == "mba" ? "Postgraduate" : "Undergraduate"} Application Form - 2024</p>
                                <p className='text[14px] font-weight-bold text-black ' style={{ fontSize: '14px' }}>Application No: {data.exam.application_no}</p>
                            </div>
                        </div>
                        <div className='mt-[7px] border border-black' style={{ width: '100%' }}>
                            <bold className='border border-black text-black font-weight-bold  pt-[3px] pb-[3px] text-[13px]' style={{ font: 'bold', fontSize: '13px', backgroundColor: '#d9d7d7' }}>
                                Application Details
                            </bold>
                            <p className='border border-black text-black font-weight-bold  pt-[3px] pb-[3px] mt-[-17px] text-[13px]' style={{ fontSize: '13px', backgroundColor: '#d9d7d7' }}>
                                Personal Details
                            </p>
                            <div className=''>
                                <table className='mt-[-17px] p-2 text-[13px] w-[100%]' style={{ fontSize: '13px', width: '100%' }} >
                                    <tr className='w-[100%]'>
                                        <td className='border border-black font-weight-bold text-black'>Name</td>
                                        <td className='border border-black text-black'>{data.name}</td>
                                        <td className='border border-black font-weight-bold text-black'>Email</td>
                                        <td className='border border-black text-black text-lowercase'>{data.email}</td>
                                        <td className='border border-black text-black' rowSpan={4} colSpan={4}><img style={{ width: '100px' }} className='w-[100px] m-auto p-2 border border-black' src={data.educationdetails.photo} alt="" /></td>

                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Mobile No</td>
                                        <td className='border border-black text-black'>+91 {data.mobileNo}</td>
                                        <td className='border border-black font-weight-bold text-black'>Country</td>
                                        <td className='border border-black text-black'>India</td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Gender</td>
                                        <td className='border border-black text-black'>{data.gender}</td>
                                        <td className='border border-black font-weight-bold text-black'>DOB</td>
                                        <td className='border border-black text-black'>{data.personaldetails.dob}</td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Father's Name</td>
                                        <td className='border border-black text-black'>{data.personaldetails.fatherName}</td>
                                        <td className='border border-black font-weight-bold text-black'>Mother's Name</td>
                                        <td className='border border-black text-black'>{data.personaldetails.motherName}</td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Guardian's Phone Number</td>
                                        <td className='border border-black text-black'>+91 {data.personaldetails.guardianNo}</td>
                                        <td className='border border-black font-weight-bold text-black'>Alternate Phone number</td>
                                        <td className='border border-black text-black'>+91 {data.personaldetails.alternateNo}</td>
                                        <td className='border border-black text-black' rowSpan={1} colSpan={4}><img style={{ width: '100px', height: '30px' }} className='w-[100px] h-[30px] m-auto  border border-black' src={data.educationdetails.signature} alt="" /></td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Blood Group</td>
                                        <td className='border border-black text-black'>{data.personaldetails.bloodGroup}</td>
                                        <td className='border border-black font-weight-bold text-black'>Category</td>
                                        <td className='border border-black text-black'>{data.personaldetails.category}</td>
                                        <td className='border border-black text-black font-weight-bold'>EWS</td>
                                        <td className='border border-black text-black'>{data.personaldetails.ews}</td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Religion</td>
                                        <td className='border border-black text-black'>{data.personaldetails.religion}</td>
                                        <td className='border border-black font-weight-bold text-black'>Aadhaar</td>
                                        <td className='border border-black text-black' colSpan={3}>{data.personaldetails.aadhaarNo}</td>
                                    </tr>
                                    <tr style={{ backgroundColor: '#d9d7d7' }}>
                                        <td colSpan={6} className='font-weight-bold text-[15px] text-black ' >Correspondence Address</td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Address Line 1</td>
                                        <td className='border border-black text-black' colSpan={5}>{data.personaldetails.addressLine1} </td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Address Line 2</td>
                                        <td className='border border-black text-black' colSpan={5}>{data.personaldetails.addressLine2} </td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>City</td>
                                        <td className='border border-black text-black'>{data.personaldetails.city}</td>
                                        <td className='border border-black font-weight-bold text-black'>State</td>
                                        <td className='border border-black text-black'>{data.personaldetails.state}</td>
                                        <td className='border border-black font-weight-bold text-black'>Pincode</td>
                                        <td className='border border-black text-black' colSpan={5}>{data.personaldetails.pincode} </td>

                                    </tr>
                                    <tr style={{ backgroundColor: '#d9d7d7' }}>
                                        <td colSpan={6} className='font-weight-bold text-[15px] text-black '>Permanent Address</td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Address Line 1</td>
                                        <td className='border border-black text-black' colSpan={5}>{data.personaldetails.paddressLine1} </td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Address Line 2</td>
                                        <td className='border border-black text-black' colSpan={5}>{data.personaldetails.paddressLine2} </td>
                                    </tr>
                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>City</td>
                                        <td className='border border-black text-black'>{data.personaldetails.pcity}</td>
                                        <td className='border border-black font-weight-bold text-black'>State</td>
                                        <td className='border border-black text-black'>{data.personaldetails.pstate}</td>
                                        <td className='border border-black font-weight-bold text-black'>Pincode</td>
                                        <td className='border border-black text-black' colSpan={5}>{data.personaldetails.ppincode} </td>

                                    </tr>
                                </table>
                                <table className=' p-2 text-[13px] w-[100%]' style={{ width: '100%' }}>
                                    <tr style={{ backgroundColor: '#d9d7d7' }}>
                                        <td colSpan={6} className='font-weight-bold text-[15px] text-black '> Examination Details</td>
                                    </tr>

                                    <tr>
                                        <td className='border border-black font-weight-bold text-black'>Program Interested</td>
                                        <td className='border border-black text-black'>{data.program}</td>
                                        <td className='border border-black font-weight-bold text-black'>Examination Mode</td>
                                        <td className='border border-black text-black'>{data.exam.moe} </td>
                                    </tr>
                                </table>
                                <table className=' p-2 text-[13px] w-[100%]' style={{ width: '100%' }}>
                                    <tr style={{ backgroundColor: '#d9d7d7' }}>
                                        <td colSpan={6} className='font-weight-bold text-[15px] text-black ' style={{ fontSize: '15px', }}>Education Qualification Details</td>
                                    </tr>
                                    <tr style={{ background: '#d9d7d7' }}>
                                        <td style={{ height: '3rem' }} className='border border-black font-weight-bold text-black'>Qualification</td>
                                        <td className='border border-black font-weight-bold text-black'>Name of Institute</td>
                                        <td className='border border-black font-weight-bold text-black'>Board</td>
                                        <td className='border border-black font-weight-bold text-black'>Result Status</td>
                                        <td className='border border-black font-weight-bold text-black'>Year of Passing</td>
                                        <td className='border border-black font-weight-bold text-black'>Obtained Percentage / CGPA</td>

                                    </tr>
                                    <tr>
                                        <td style={{ height: '3rem' }} className='border border-black font-weight-bold  text-black'>10th</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.tenthSchool}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.tenthBoard}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.tenthResultStatus}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.tenthYOP}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.tenthPer}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: '3rem' }} className='border border-black font-weight-bold  text-black'>12th</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.twelveSchool}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.twelveBoard}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.twelveResultStatus}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.twelveYOP}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.twelvePer}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: '3rem' }} className='border border-black font-weight-bold  text-black'>Graduation</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.gradSchool}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.gradBoard}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.gradResultStatus}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.gradYOP}</td>
                                        <td className='border border-black  text-black'>{data.educationdetails.gradPer}</td>
                                    </tr>
                                </table>

                            </div>
                        </div>
                      
                        <table className=' p-2 text-[13px] w-[100%]' style={{ fontSize: '13px', width: '100%' }}>
                            <tr style={{ background: '#d9d7d7' }}>
                                <td colSpan={6} className='font-weight-bold text-[15px] text-black '>Counselling Payment Details</td>
                            </tr>

                            <tr>
                                <td className='border border-black font-weight-bold text-black'>Payment Mode</td>
                                <td className='border border-black text-black'>{data.payments.payMode}</td>
                                <td className='border border-black font-weight-bold text-black'>Transaction ID</td>
                                <td className='border border-black text-black'>{data.payments.transId} </td>

                            </tr>
                            <tr>
                                <td className='border border-black font-weight-bold text-black'>Transaction Date</td>
                                <td className='border border-black text-black'>{data.payments.payDate}</td>
                                <td className='border border-black font-weight-bold text-black'>Amount</td>
                                <td className='border border-black text-black' colSpan={3}>{data.payments.amount}</td>
                            </tr>
                        </table>
                        <table className=' p-2 text-[13px] w-[100%]' style={{ fontSize: '13px', width: '100%' }}>
                            <tr style={{ backgroundColor: '#d9d7d7' }}>
                                <td colSpan={6} className='font-weight-bold text-[15px] text-black '>Partial Payment Details</td>
                            </tr>

                            <tr>
                                <td className='border border-black font-weight-bold text-black'>Payment Mode</td>
                                <td className='border border-black text-black'>{data.payments.partial_payMode}</td>
                                <td className='border border-black font-weight-bold text-black'>Transaction ID</td>
                                <td className='border border-black text-black'>{data.payments.partial_transId} </td>

                            </tr>
                            <tr>
                                <td className='border border-black font-weight-bold text-black'>Transaction Date</td>
                                <td className='border border-black text-black'>{data.payments.partial_payDate}</td>
                                <td className='border border-black font-weight-bold text-black'>Amount</td>
                                <td className='border border-black text-black' colSpan={3}>{data.payments.partial_amount}</td>
                            </tr>
                        </table>
                        {
                            data.collegedetails.is_college_locked && <>
                                {
                                    data.collegedetails.round_one_action == "Freeze" && <>
                                        <table className=' p-2 text-[13px] w-[100%]' style={{ fontSize: '13px', width: '100%' }}>
                                            <tr style={{ backgroundColor: '#d9d7d7' }}>
                                                <td colSpan={6} className='font-weight-bold text-[15px] text-black '>Alloted College Details</td>
                                            </tr>
                                            <tr >
                                                <td colSpan={1} className='border border-black font-weight-bold text-black'>College Name</td>
                                                <td colSpan={5} className='border border-black text-black'>{ data.collegedetails.round_one_alloted_college.college_name}</td>

                                            </tr>
                                            <tr>
                                                <td className='border border-black font-weight-bold text-black'>College Code</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_one_alloted_college.college_code} </td>
                                                <td className='border border-black font-weight-bold text-black'>College State</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_one_alloted_college.college_state}</td>

                                            </tr>
                                            <tr>
                                                <td className='border border-black font-weight-bold text-black'>Program</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_one_alloted_college.college_program}</td>
                                                <td className='border border-black font-weight-bold text-black'>Branch</td>
                                                <td className='border border-black text-black' colSpan={3}>{data.collegedetails.round_one_alloted_college.college_branch}</td>
                                            </tr>
                                        </table>
                                    </>
                                }
                                {
                                    data.collegedetails.round_two_action == "Freeze" && <>
                                    <table className=' p-2 text-[13px] w-[100%]' style={{ fontSize: '13px', width: '100%' }}>
                                            <tr style={{ backgroundColor: '#d9d7d7' }}>
                                                <td colSpan={6} className='font-weight-bold text-[15px] text-black '>Alloted College Details</td>
                                            </tr>
                                            <tr >
                                                <td colSpan={1} className='border border-black font-weight-bold text-black'>College Name</td>
                                                <td colSpan={5} className='border border-black text-black'>{ data.collegedetails.round_two_alloted_college.college_name}</td>

                                            </tr>
                                            <tr>
                                                <td className='border border-black font-weight-bold text-black'>College Code</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_two_alloted_college.college_code} </td>
                                                <td className='border border-black font-weight-bold text-black'>College State</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_two_alloted_college.college_state}</td>

                                            </tr>
                                            <tr>
                                                <td className='border border-black font-weight-bold text-black'>Program</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_two_alloted_college.college_program}</td>
                                                <td className='border border-black font-weight-bold text-black'>Branch</td>
                                                <td className='border border-black text-black' colSpan={3}>{data.collegedetails.round_two_alloted_college.college_branch}</td>
                                            </tr>
                                        </table>
                                    </>
                                }
                                {
                                    data.collegedetails.round_three_action == "Freeze" && <>
                                    <table className=' p-2 text-[13px] w-[100%]' style={{ fontSize: '13px', width: '100%' }}>
                                            <tr style={{ backgroundColor: '#d9d7d7' }}>
                                                <td colSpan={6} className='font-weight-bold text-[15px] text-black '>Alloted College Details</td>
                                            </tr>
                                            <tr >
                                                <td colSpan={1} className='border border-black font-weight-bold text-black'>College Name</td>
                                                <td colSpan={5} className='border border-black text-black'>{ data.collegedetails.round_three_alloted_college.college_name}</td>

                                            </tr>
                                            <tr>
                                                <td className='border border-black font-weight-bold text-black'>College Code</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_three_alloted_college.college_code} </td>
                                                <td className='border border-black font-weight-bold text-black'>College State</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_three_alloted_college.college_state}</td>

                                            </tr>
                                            <tr>
                                                <td className='border border-black font-weight-bold text-black'>Program</td>
                                                <td className='border border-black text-black'>{data.collegedetails.round_three_alloted_college.college_program}</td>
                                                <td className='border border-black font-weight-bold text-black'>Branch</td>
                                                <td className='border border-black text-black' colSpan={3}>{data.collegedetails.round_three_alloted_college.college_branch}</td>
                                            </tr>
                                        </table>
                                    </>
                                }
                            </>
                        }


                        <table className='border border-black p-2 text-[13px] w-[100%]' style={{ fontSize: '13px', width: '100%', marginTop: '30px' }} >
                            <tr className='border border-black' style={{ background: '#d9d7d7' }}>
                                <td colSpan={6} className='font-weight-bold text-[15px] text-black border border-black text-center'>List of Documents Submitted by {data.name}</td>
                            </tr>
                            <tr className='border border-black'>
                                <td className='w-[100px] font-weight-bold text-[15px] text-black border border-black'>S.NO</td>
                                <td colSpan={3} className='font-weight-bold text-[15px] text-black border border-black'>Document</td>
                                <td colSpan={2} className='font-weight-bold text-[15px] text-black border border-black'>Download</td>
                            </tr>
                            <tr className='border border-black'>
                                <td className='w-[100px]  text-[15px] text-black border border-black'>1</td>
                                <td colSpan={3} className=' text-[15px] text-black border border-black'>1Oth MARKSHEET</td>
                                <td colSpan={2} className='font-weight-bold text-[15px] text-black border border-black'> <Link to={`https://counselling.aicee.in/api/v1/download/${data.educationdetails.tenthCert}`} target='_blank'>Download</Link> </td>
                            </tr>
                            <tr className='border border-black'>
                                <td className='w-[100px]  text-[15px] text-black border border-black'>2</td>
                                <td colSpan={3} className=' text-[15px] text-black border border-black'>12th MARKSHEET</td>
                                <td colSpan={2} className='font-weight-bold text-[15px] text-black border border-black'> <Link to={`https://counselling.aicee.in/api/v1/download/${data.educationdetails.twelveCert}`} target='_blank'>Download</Link> </td>
                            </tr>

                            <tr className='border border-black'>
                                <td className='w-[100px]  text-[15px] text-black border border-black'>8</td>
                                <td colSpan={3} className=' text-[15px] text-black border border-black'>PHOTOS</td>
                                <td colSpan={2} className='font-weight-bold text-[15px] text-black border border-black'> <Link to={data.educationdetails.photo} target='_blank'>Download</Link> </td>
                            </tr>
                            <tr className='border border-black'>
                                <td className='w-[100px]  text-[15px] text-black border border-black'>8</td>
                                <td colSpan={3} className=' text-[15px] text-black border border-black'>SIGNATURE</td>
                                <td colSpan={2} className='font-weight-bold text-[15px] text-black border border-black'> <Link target='_blank' to={data.educationdetails.signature} >Download</Link> </td>
                            </tr>
                            <tr className='border border-black'>
                                <td className='w-[100px]  text-[15px] text-black border border-black'>9</td>
                                <td colSpan={3} className=' text-[15px] text-black border border-black'>AADHAAR</td>
                                <td colSpan={2} className='font-weight-bold text-[15px] text-black border border-black'> <Link to={`https://counselling.aicee.in/api/v1/download/${data.educationdetails.aadharDocs}`} target='__blank'>Download</Link> </td>
                            </tr>
                        </table>
                        
                        <center><button onClick={() => { window.print() }} className='border border-black pl-3 pr-3 mt-4 mb-4'>Print</button></center>


                    </AcknowledgeMentCSS>
                }
            </div>
        </>
    )
}

const AcknowledgeMentCSS = styled.main`
    *{
        font-family: "Sofia", sans-serif;
        text-transform: capitalize;
    }
`

export default StudentProfile