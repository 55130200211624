import React, { useState, useEffect } from 'react'
import { Header, Menu } from '../components/Index'
function RefferalLink() {
  const [refferalUser, setRefferalUser] = useState({})
  console.log(refferalUser)
  const refferalUserData = async () => {
    fetch('/total-refferal-link')
      .then(response => response.json())
      .then(data => {
        setRefferalUser(data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const refferalUserDataForBdm = async (id) => {
    fetch(`/total-refferal-link/${id}`)
      .then(response => response.json())
      .then(data => {
        setRefferalUser(data.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    refferalUserData()
    const role = localStorage.getItem('role')
    const id = localStorage.getItem('id')
    
    if(role == "Admin"){
      refferalUserData()
    }
    else{
      refferalUserDataForBdm(id)
    }
  }, [])
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
      <h1 className='text-center' style={{textDecoration:'underline'}}>All Refferal</h1>
        {
          refferalUser.length>=1 && (
            <div className='p-2'>
              <div class="table-responsive">
                <table class="table table-bordered table-dark">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">mobileNo</th>
                      <th scope="col">Username</th>
                      <th scope="col">Password</th>
                      <th scope="col">Refferal Code</th>
                      <th scope="col">Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      refferalUser.map((e, i) => {
                        return (
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td>{e.name}</td>
                            <td>{e.mobileNo}</td>
                            <td>{e.email}</td>
                            <td>{e.password}</td>
                            <td>{e.refferalCode}</td>
                            <td>aicee.in/registration/{e.refferalCode}</td>
                          </tr>
                        )
                      })
                    }


                  </tbody>
                </table>
              </div>
            </div>
          )
        }

      </div>
    </div>
  )
}

export default RefferalLink