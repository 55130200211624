import React, { useState, useEffect } from 'react'
// import Payment from './'
import Header from '../Header';
import Menu from '../Menu';
import Engg from './Engg/CompletedApplication';
import MBA from './MbaPgdm/CompletedApplication';
import Other from './Other/CompletedApplication';
import PCB from './PCB/CompletedApplication';
import PCM from './PCM/CompletedApplication';
import axios from 'axios';
import PartialStudentTable from './PartialStudentTable';
function PartialStudents() {
    const [state, setState] = useState('engg')
    const [mbaPartial, setMbaPartial] = useState([])
    const [enggPartial, setEnggPartial] = useState([])
    const [pcmPartial, setPcmPartial] = useState([])
    const [pcbPartial, setPcbPartial] = useState([])
    const [otherPartial, setOtherPartial] = useState([])
    const [partial, setPartial] = useState()
    console.log(mbaPartial)
    const mbaUrl = '/mba-students-partial'
    const enggUrl = '/engg-students-partial'
    const pcmUrl = '/pcm-students-partial'
    const pcbUrl = '/pcb-students-partial'
    const otherUrl = '/Other-students-partial'
    
    React.useEffect(() => {
        axios.get('/all-partial-applications').then((response)=>{
            setPartial(response.data.data)
          })
    }, [])
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper" style={{ backgroundColor: '#F5F7FA' }}>
                <div>




                    <div class="alert alert-dark" role="alert">
                       Total Partial Application
                    </div>
                    <PartialStudentTable />



                </div>
               
               
            </div>
        </div>
    )
}

export default PartialStudents