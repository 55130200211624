export const DISPLAY_ALERT = 'SHOW_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'

export const REGISTER_REFFERAL_BEGIN = 'REGISTER_REFFERAL_BEGIN'
export const REGISTER_REFFERAL_SUCCESS = 'REGISTER_REFFERAL_SUCCESS'
export const REGISTER_REFFERAL_ERROR = 'REGISTER_REFFERAL_ERROR'

export const LOGIN_USER_BEGIN = 'LOGIN_USER_BEGIN'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'


export const REFFERAL_LOGIN_USER_BEGIN = 'REFFERAL_LOGIN_USER_BEGIN'
export const REFFERAL_LOGIN_USER_SUCCESS = 'REFFERAL_LOGIN_USER_SUCCESS'
export const REFFERAL_LOGIN_USER_ERROR = 'REFFERAL_LOGIN_USER_ERROR'