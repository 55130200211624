import React,{useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { useAppContext } from '../../context/appContext'
import {Alert} from '../Index'
import Wrapper from '../Wrapper'
function Login() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {user,isLoading, showAlert, displayAlert,bdmLoginUser } = useAppContext()
    const onSubmit = (e) => {
        e.preventDefault()
        
        if (!email || !password) {
          displayAlert()
          return
        }
        const currentUser = {email, password}
        bdmLoginUser(currentUser)
        // console.log(values)
    
      }
      useEffect(()=>{
        if(user){
          setTimeout(()=>{
            navigate('/')
          },1)
        }
        else{
          navigate('/bdm/login')
        }
      },[user,navigate])
    return (
        <Wrapper>
            <div style={{ backgroundColor: '#222D32', height: '100vh' }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-2" />
                    <div className="col-lg-6 col-md-8 login-box">
                        <div className="col-lg-12 login-key">
                            <i className="fa fa-key" aria-hidden="true" />
                        </div>
                        <div className="col-lg-12 login-title">BDM LOGIN PANEL</div>
                        {showAlert && (<Alert/>)}
                        <div className="col-lg-12 login-form">
                            <div className="col-lg-12 login-form">
                                <form onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <label className="form-control-label">USERNAME</label>
                                        <input value={email} onChange={(e)=>{setEmail(e.target.value)}} type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-control-label">PASSWORD</label>
                                        <input value={password} onChange={(e)=>{setPassword(e.target.value)}} type="password" className="form-control" i="" />
                                    </div>
                                    <center>
                                        <button type="submit" className="btn btn-outline-primary mb-3">
                                            LOGIN
                                        </button>
                                    </center>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-2" />
                    </div>
                </div>
            </div>

        </div>
        </Wrapper>
    )
}

export default Login