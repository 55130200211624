import React,{useState,useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { useAppContext } from '../context/appContext'
import {Alert} from './Index'

function Login() {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    // async function loginUser(event) {
    //     // event.preventDefault()
    //     if (!email || !password) {
    //         alert('invalid credentials')
    //     }
    //     else {
    //         const response = await fetch('/admin/login', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 emailId: email,
    //                 password: password
    //             })
    //         })
    //         const data = await response.json()
    //         console.log(data)
    //         if (data.message === 'this email id does not exist') {
    //             alert('Email id does not exist')
    //         }
    //         else if (data.message === 'please enter correct password') {
    //             alert('please enter correct password')
    //         }
    //         else {
    //             navigate('/')
    //         }
    
    //         //   console.log(data)
    //     }
    
    // }
    // login end
    const {user,isLoading, showAlert, displayAlert,loginUser } = useAppContext()
    const onSubmit = (e) => {
        e.preventDefault()
        
        if (!email || !password) {
          displayAlert()
          return
        }
        const currentUser = {email, password}
        loginUser(currentUser)
        // console.log(values)
    
      }
      useEffect(()=>{
        if(user){
          setTimeout(()=>{
            navigate('/')
          },1)
        }
        else{
          navigate('/login')
        }
      },[user,navigate])
    return (
        <div>
            <section className="vh-100 gradient-custom">
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div
                                className="card bg-dark text-white"
                                style={{ borderRadius: "1rem" }}
                            >
                                <form className="card-body p-5 text-center" onSubmit={onSubmit}>
                                    <div className="mb-md-5 mt-md-4 pb-5">
                                        <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                                        {showAlert && (<Alert/>)}
                                        <div className="form-outline form-white mb-4">
                                            <label className="form-label" htmlFor="typeEmailX">
                                                UserName
                                            </label>
                                            <input
                                            value={email}
                                            onChange={(e)=>{setEmail(e.target.value)}}
                                                type="email"
                                                id="typeEmailX"
                                                className="form-control form-control-lg"
                                            />
                                        </div>
                                        <div className="form-outline form-white mb-4">
                                            <label className="form-label" htmlFor="typePasswordX">
                                                Password
                                            </label>
                                            <input
                                            value={password}
                                            onChange={(e)=>{setPassword(e.target.value)}}
                                                type="password"
                                                id="typePasswordX"
                                                className="form-control form-control-lg"
                                            />
                                        </div>

                                        <button
                                        disabled={isLoading}
                                        type='submit'
                                            className="btn btn-outline-light btn-lg px-5"
                                            // onClick={()=>{loginUser()}}
                                        >
                                            Login
                                        </button>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Login