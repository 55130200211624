import React from 'react';
// import Sidebar from './Sidebar'
//Bootstrap and jQuery libraries
// import 'bootstrap/dist/css/bootstrap.min.css';

import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { Link } from 'react-router-dom'
import $ from 'jquery';
//For API Requests
import axios from 'axios';
import { Header, Menu } from '../components/Index'
class RefferalStudent extends React.Component {
    // State array variable to save and show data

    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }

    componentDidMount() {



        //Get all users details in bootstrap table
        axios.get(`/total-refferal-student`).then(res => {
            //Storing users detail in state array object
            this.setState({ data: res.data.data });
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 10,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: [
                            'copyHtml5',
                            'excelHtml5',
                            'csvHtml5',
                            'print',
                            'pdf'
                        ]
                    }
                );
            },
                1000
            );
        });
    }
    render() {
        //Datatable HTML
        return (
            <div>
                <Header />
                <Menu />
                <div className='content-wrapper'>
                    <h1>Total Refferal Student</h1>


                    <div style={{ width: '100%' }}>

                        <div className="flex flex-col md:flex-row" style={{ width: '100%' }}>
                            {/* <Sidebar /> */}
                            <section style={{ width: '100%' }}>
                                <div
                                    id="main"
                                    className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5"
                                    style={{ width: '100%' }}
                                >
                                    <div className="flex flex-wrap bg-white" style={{ width: '100%' }}>


                                        <div className="flex flex-col bg-white" style={{ width: '100%' }}>
                                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                                    <div className="overflow-x-auto">
                                                        <div className="MainDiv">

                                                            <div className="container p-5">

                                                                <table id="example" class="table table-hover table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Sl.No</th>
                                                                            <th>Full Name</th>
                                                                            <th>Gender</th>
                                                                            <th>Email</th>
                                                                            <th>Mobile No</th>
                                                                            <th>Program</th>
                                                                            <th>Refferal Code</th>
                                                                            <th>Application Status</th>


                                                                            {/* <th>Action</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.data.map((result, slNo) => {
                                                                            {/* console.log(result) */ }
                                                                            return (

                                                                                <tr>
                                                                                    <td>{slNo + 1}</td>
                                                                                    <td>{result.name}</td>
                                                                                    <td> {result.gender} </td>
                                                                                    <td> {result.email} </td>
                                                                                    <td>{result.mobileNo}</td>
                                                                                    <td>{result.program}</td>
                                                                                    <td> {result.refferalCode}</td>
                                                                                    {result.engg || result.mba || result.pcm || result.pcb || result.other ?
                                                                                        <td style={{ backgroundColor: "green", textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                                                            Completed
                                                                                        </td>
                                                                                        :
                                                                                        <td style={{ backgroundColor: "red", textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
                                                                                            Not Completed
                                                                                        </td>
                                                                                    }

                                                                                </tr>

                                                                            )
                                                                        })}


                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default RefferalStudent;