import React from 'react'

function FormInput({label,placeholder,type,handleChange,value,name}) {
    return (
        <div>
            <label for="exampleInputEmail1">{label}</label>
            <input autocomplete="off" type={type} class="form-control" name={name} value={value} id="exampleInputEmail1" onChange={handleChange} aria-describedby="emailHelp" placeholder={placeholder} />
        </div>
    )
}

export default FormInput