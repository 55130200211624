import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../Header'
import Menu from '../Menu'
// import React from 'react'
import { useAppContext } from '../context/appContext'
import axios from 'axios'
import YoutuberRefferal from '../pages/YoutuberRefferal'
import BdmRefferal from '../pages/BdmRefferal'
function Index() {
  const { user } = useAppContext()
  const navigate = useNavigate()
  const [mbaPartial, setMbaPartial] = useState([])
  const [application, setApplication] = useState()
  console.log("application" + application)
  const [partial, setPartial] = React.useState()
  useEffect(() => {
    axios.get('/counselling/dashboard-count-data').then((response) => {
      setApplication(response.data.data)
      console.log("application", response.data)
    })


  }, [])
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">AICEE - Counselling - 2024</h1>
              </div>{/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Dashboard v1</li>
                </ol>
              </div>{/* /.col */}
            </div>{/* /.row */}
          </div>{/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <div className="col-md-12 " >
          <div className="row ">

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-orange-dark" style={{ cursor: 'pointer' }}>
                <div className="card-statistic-3 p-4" onClick={() => { navigate('/counselling/dashboard/students') }}>
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                  </div>
                  <div className="mb-4">
                    <h5 className="card-title mb-0">Total Students</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">{application && application.total_student}</h2>
                    </div>
                    <div className="col-4 text-right">
                      <span>
                        <i className="fa fa-arrow-up" />
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                    <div
                      className="progress-bar l-bg-cyan"
                      role="progressbar"
                      data-width="25%"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-3 col-lg-6">
              <div className="card c-bg-cherry">
                <div className="card-statistic-3 p-4" style={{ cursor: 'pointer' }} onClick={() => { navigate('/counselling/dashboard/counselling-payments') }}>
                  <div className="card-icon card-icon-large" style={{ cursor: 'pointer' }}>
                    <i className="fas fa-users" style={{ cursor: 'pointer' }} />
                  </div>
                  <div className="mb-4">
                    <h5 className="card-title mb-0">Counselling Payments</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">{application && application.total_counselling_payment}</h2>
                    </div>
                    <div className="col-4 text-right">
                      <span>
                        <i className="fa fa-arrow-up" />
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                    <div
                      className="progress-bar l-bg-cyan"
                      role="progressbar"
                      data-width="25%"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-blue-dark" style={{ cursor: 'pointer' }}>
                <div className="card-statistic-3 p-4" onClick={() => { navigate('/counselling/dashboard/partial-payments') }}>
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                  </div>
                  <div className="mb-4">
                    <h5 className="card-title mb-0">Partial Fee</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">{application && application.partialFee}</h2>
                    </div>
                    <div className="col-4 text-right">
                      <span>
                        <i className="fa fa-arrow-up" />
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                    <div
                      className="progress-bar l-bg-green"
                      role="progressbar"
                      data-width="25%"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-green-dark" style={{ cursor: 'pointer' }}>
                <div className="card-statistic-3 p-4" onClick={() => { navigate('/counselling/dashboard/seat-confirm') }}>
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                  </div>
                  <div className="mb-4">
                    <h5 className="card-title mb-0">Seat Confirm</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">{application && application.seatConfirm}</h2>
                    </div>
                    <div className="col-4 text-right">
                      <span>
                        <i className="fa fa-arrow-up" />
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                    <div
                      className="progress-bar l-bg-orange"
                      role="progressbar"
                      data-width="25%"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-sky-dark" style={{ cursor: 'pointer' }}>
                <div className="card-statistic-3 p-4" onClick={() => { navigate('/counselling/dashboard/round-1') }}>
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                  </div>
                  <div className="mb-4">
                    <h5 className="card-title mb-0">Round 1</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">{application && application.roundOne}</h2>
                    </div>
                    <div className="col-4 text-right">
                      <span>
                        <i className="fa fa-arrow-up" />
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                    <div
                      className="progress-bar l-bg-cyan"
                      role="progressbar"
                      data-width="25%"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-reg-dark" style={{ cursor: 'pointer' }}>
                <div className="card-statistic-3 p-4" onClick={() => { navigate('/counselling/dashboard/round-2') }}>
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                  </div>
                  <div className="mb-4">
                    <h5 className="card-title mb-0">Round 2</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">{application && application.roundTwo}</h2>
                    </div>
                    <div className="col-4 text-right">
                      <span>
                        <i className="fa fa-arrow-up" />
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                    <div
                      className="progress-bar l-bg-cyan"
                      role="progressbar"
                      data-width="25%"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "25%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-reg-dark" style={{ cursor: 'pointer' }}>
                <div className="card-statistic-3 p-4" onClick={() => { navigate(`/counselling/dashboard/round-3`) }}>
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                  </div>
                  <div className="mb-4">
                    <h5 className="card-title mb-0">Round 3</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">{application && application.roundThree}</h2>
                    </div>
                    <div className="col-4 text-right">
                      <span>
                        <i className="fa fa-arrow-up" />
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                    <div
                      className="progress-bar l-bg-cyan"
                      role="progressbar"
                      data-width="25%"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "75%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-reg-red" style={{ cursor: 'pointer' }}>
                <div className="card-statistic-3 p-4" onClick={() => { navigate(`/counselling/dashboard/spot-round`) }}>
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                  </div>
                  <div className="mb-4">
                    <h5 className="card-title mb-0">Spot Round</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-right">
                      <span>
                        <i className="fa fa-arrow-up" />
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                    <div
                      className="progress-bar l-bg-cyan"
                      role="progressbar"
                      data-width="25%"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "75%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className=' ml-2' style={{ backgroundColor: 'gray', padding: '1rem 3.9rem', color: 'white', fontWeight: 'bold', borderRadius: '10px', cursor: 'pointer' }} onClick={() => { navigate('/counselling/change-markey') }}>
              Change Marque
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index


