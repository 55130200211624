import React from 'react';
// import Sidebar from './Sidebar'
//Bootstrap and jQuery libraries
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
//For API Requests
import axios from 'axios';
class CB extends React.Component {
    // State array variable to save and show data
    constructor(props) {
        super(props)
        this.state = {
            data: [],

        }
    }
    componentDidMount() {
        //Get all users details in bootstrap table
        axios.get('/pcm-students-center-based').then(res => {
            //Storing users detail in state array object
            this.setState({ data: res.data });

        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 5,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: ['copyHtml5',
                            'excelHtml5',
                            'csvHtml5',
                            'print',
                            'pdf'
                        ]
                    }
                );
            },
                1000
            );
        });
    }
    render() {
        //Datatable HTML
        return (
            <div style={{ width: '100%' }}>

                <div className="flex flex-col md:flex-row" style={{ width: '100%' }}>
                    {/* <Sidebar /> */}
                    <section style={{ width: '100%' }}>
                        <div
                            id="main"
                            className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5"
                            style={{ width: '100%' }}
                        >

                            <div className="flex flex-wrap bg-white" style={{ width: '100%' }}>


                                <div className="flex flex-col bg-white" style={{ width: '100%' }}>
                                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="overflow-x-auto">
                                                <div className="MainDiv">




                                                    <div className="container p-5 table-responsive">

                                                    <table id="example" class="table table-hover table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sl.No</th>
                                                                    <th>Application No</th>
                                                                    <th>Amount</th>
                                                                    <th>Student Name</th>
                                                                    <th>Email-ID</th>
                                                                    <th>Mobile No</th>
                                                                    <th>DOB</th>
                                                                    <th>Level Of Exam</th>
                                                                    <th>Program Interested</th>
                                                                    <th>Exam City</th>
                                                                    <th>Exam State</th>
                                                                    <th>Exam Date</th>
                                                                    <th>Address Line1</th>
                                                                    <th>Address Line2</th>
                                                                    <th>City</th>
                                                                    <th>State</th>
                                                                    <th>Pincode</th>

                                                                    <th>Payment Status</th>
                                                                    {/* <th>Action</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.data.map((result, slNo) => {
                                                                    {/* console.log(result.paymentResponseMBA) */ }
                                                                    const status = result.paymentResponsePCM[result.paymentResponsePCM.length - 1].paymentStatus
                                                                    const paymentData = status.trim().split('&')
                                                                    const paymentStatus = paymentData[3].trim().split('=')
                                                                    const paymentAmount = paymentData[35].trim().split('=')
                                                                    return (

                                                                        <tr>
                                                                            <td>{slNo + 1}</td>
                                                                            <td>{result.PCMApplicationNo}</td>
                                                                            <td>{paymentAmount[1]}</td>
                                                                            <td>{result.studentName}</td>
                                                                            <td>{result.emailId}</td>
                                                                            <td>{result.mobileNo}</td>
                                                                            <td>{result.dob}</td>
                                                                            <td>PCM</td>
                                                                            <td>{result.pcmProgramme}</td>
                                                                            <td>{result.PCMexamCity}</td>
                                                                            <td>{result.PCMexamState}</td>
                                                                            <td>29th jan 2023</td>
                                                                            <td>{result.addressLine1}</td>
                                                                            <td>{result.addressLine2}</td>
                                                                            <td>{result.city}</td>
                                                                            <td>{result.state}</td>
                                                                            <td>{result.pincode}</td>



                                                                            <td>{paymentStatus[1]}</td>
                                                                            {/* <td><button className='primary'>View</button></td> */}

                                                                        </tr>

                                                                    )
                                                                })}


                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>




                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
export default CB;