import React,{useState,useEffect} from 'react'
import { Header,Menu } from '../Index'
import RefferalUserListTable from './RefferalUserListTable'
function RefferalList() {
 
  return (
    <div>
        <Header />
        <Menu />
        <div className='content-wrapper'>
        <h1>Total Refferal User List</h1>
            <RefferalUserListTable/>
        </div>
    </div>
  )
}

export default RefferalList