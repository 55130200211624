import { DISPLAY_ALERT,CLEAR_ALERT,REGISTER_REFFERAL_BEGIN,REGISTER_REFFERAL_ERROR,REGISTER_REFFERAL_SUCCESS,LOGIN_USER_BEGIN,LOGIN_USER_ERROR,LOGIN_USER_SUCCESS,REFFERAL_LOGIN_USER_BEGIN,REFFERAL_LOGIN_USER_ERROR,REFFERAL_LOGIN_USER_SUCCESS } from "./action";

const reducer = (state,action)=>{
    if(action.type == DISPLAY_ALERT){
        return{...state,showAlert:true,alertType:"danger",alertText:'Please Provide All values !'}
    }
    if(action.type == CLEAR_ALERT){
        return{...state,showAlert:false,alertType:"",alertText:''}
    }if(action.type === REGISTER_REFFERAL_BEGIN){
        return{

            ...state,
            isLoading:true
        }
    }
    if(action.type === REGISTER_REFFERAL_SUCCESS){
        return {
            ...state,
            isLoading:false,
            // user:action.payload.user,
            showAlert:true,
            // role:action.payload.role,
            alertType:'success',
            alertText:'Refferal User Created Successfully...'
        }
    }
    if(action.type === REGISTER_REFFERAL_ERROR){
        return {
            ...state,
            isLoading:false,
            showAlert:true,
            alertType:'danger',
            alertText:action.payload.msg
        }
    }
    if(action.type === LOGIN_USER_BEGIN){
        return {
            ...state,
            isLoading:true
        }
    }
    if(action.type === LOGIN_USER_SUCCESS){
        return {
            ...state,
            isLoading:false,
            token:action.payload.token,
            user:action.payload.user,
            showAlert:true,
            alertType:'success',
            alertText:'Login Successful! Redirecting...'
        }
    }
    if(action.type === LOGIN_USER_ERROR){
        return {
            ...state,
            isLoading:false,
            showAlert:true,
            alertType:'danger',
            alertText:action.payload.msg
        }
    }
    if(action.type === REFFERAL_LOGIN_USER_BEGIN){
        return {
            ...state,
            isLoading:true
        }
    }
    if(action.type === REFFERAL_LOGIN_USER_SUCCESS){
        return {
            ...state,
            isLoading:false,
            token:action.payload.token,
            user:action.payload.user,
            showAlert:true,
            alertType:'success',
            alertText:'Login Successful! Redirecting...'
        }
    }
    if(action.type === REFFERAL_LOGIN_USER_ERROR){
        return {
            ...state,
            isLoading:false,
            showAlert:true,
            alertType:'danger',
            alertText:action.payload.msg
        }
    }
}

export{reducer}