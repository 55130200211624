import React, { useState } from 'react'
import { FormInput, Header, Menu, Alert, FormSelect } from '../Index'
import {useAppContext} from '../../context/appContext'
import {useNavigate,useParams} from 'react-router-dom'
import ViewReportTable from './ViewReportTable'
function ViewReportRefferal() {
    const refferalId = useParams()
    console.log(refferalId.id)
    const navigate = useNavigate()
    const {user,isLoading,showAlert,displayAlert,registerUser} = useAppContext()
    // console.log(user,isLoading,showAlert,displayAlert)
   
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">
                <h1>Refferal Code : {refferalId.id}</h1>
                <ViewReportTable refferalId={refferalId}/>
            </div>8
        </div>
    )
}

export default ViewReportRefferal