import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Engg from './EnggRefferal';
import MBA from './MbaRefferal';
import Other from './OtherRefferal';
import PCB from './PcbRefferal';
import PCM from './PcmRefferal';
import YoutuberRefferal from '../../pages/YoutuberRefferal';
function RefferalClientDashboard() {
    const [state, setState] = useState('engg')
    const [mbaPartial, setMbaPartial] = useState([])
    const [enggPartial, setEnggPartial] = useState([])
    const [pcmPartial, setPcmPartial] = useState([])
    const [pcbPartial, setPcbPartial] = useState([])
    const [otherPartial, setOtherPartial] = useState([])
    console.log(mbaPartial)



    // refferal Id
    const refferalId = localStorage.getItem('emailId')


    const mbaUrl = `/api/refferal/mba/reg-students/${refferalId}`
    const enggUrl = `/api/refferal/engg/reg-students/${refferalId}`
    const pcmUrl = `/api/refferal/pcm/reg-students/${refferalId}`
    const pcbUrl = `/api/refferal/pcb/reg-students/${refferalId}`
    const otherUrl = `/api/refferal/other/reg-students/${refferalId}`



    React.useEffect(() => {
        axios.get(mbaUrl).then((response) => {
            setMbaPartial(response.data)
        })
        axios.get(enggUrl).then((response) => {
            setEnggPartial(response.data)
        })
        axios.get(pcmUrl).then((response) => {
            setPcmPartial(response.data)
        })
        axios.get(pcbUrl).then((response) => {
            setPcbPartial(response.data)
        })
        axios.get(otherUrl).then((response) => {
            setOtherPartial(response.data)
        })
    }, [])
    return (


        <div style={{ width: '100%' }}>

            <div className="flex flex-col md:flex-row" style={{ width: '100%' }}>
                {/* <Sidebar /> */}
                <section style={{ width: '100%' }}>
                    <div
                        id="main"
                        className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5"
                        style={{ width: '100%' }}
                    >
                        <div>
                            <div>

                            </div>
                            <div className='p-2'>
                            <div class="alert alert-dark" role="alert">
                                                Total Application
                                            </div>
                                            <YoutuberRefferal />

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default RefferalClientDashboard