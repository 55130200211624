import React,{useReducer,useContext} from "react";
import { DISPLAY_ALERT,CLEAR_ALERT,REGISTER_REFFERAL_BEGIN,REGISTER_REFFERAL_ERROR,REGISTER_REFFERAL_SUCCESS,LOGIN_USER_BEGIN,LOGIN_USER_ERROR,LOGIN_USER_SUCCESS,REFFERAL_LOGIN_USER_BEGIN,REFFERAL_LOGIN_USER_ERROR,REFFERAL_LOGIN_USER_SUCCESS } from "./action";
import {reducer} from './reducer'
import axios from 'axios'
// import { useNavigate } from "react-router-dom";
const token = localStorage.getItem('token')
const user = localStorage.getItem('user')
const role = localStorage.getItem('role')
const initialState = {
    isLoading:false,
    showAlert:false,
    alertText:'',
    alertType:'',
    user:user ? JSON.parse(user):null,
    token:token,
    role:role
   
}
const AppContext = React.createContext()
const AppProvider = ({children})=>{
    const [state,dispatch] = useReducer(reducer,initialState) 
    const displayAlert = ()=>{
        dispatch({type:DISPLAY_ALERT})
        clearAlert()
    }
    const clearAlert = ()=>{
        setTimeout(() => {
            dispatch({type:CLEAR_ALERT})
        }, 3000);
    }
    const addUserToLocalStorage = ({user,token,role})=>{
        localStorage.setItem('user',JSON.stringify(user))
        localStorage.setItem('token',token)
        localStorage.setItem('role',role)
        localStorage.setItem('email',user.email)
        localStorage.setItem('id',user._id)
        
    }
    const removeUserFromLocalStorage = ()=>{
        // localStorage.removeItem('token')
        // localStorage.removeItem('user')
        // localStorage.removeItem('role')
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        localStorage.setItem('role')
        console.log(user)
        // navigate('/login')
    }
    const registerUser = async (currentUser)=>{
        // console.log(currentUser)
        dispatch({type:REGISTER_REFFERAL_BEGIN})
        try {
            const response = await axios.post('/api/v1/auth/register',currentUser)
            // console.log(response)
            const {user,token,role} = response.data
            dispatch({
                type:REGISTER_REFFERAL_SUCCESS,
                payload:{user,token,role}
            })
            // addUserToLocalStorage({user,token,role})
            // local storage later
        } catch (error) {
            console.log(error.response)
            dispatch({
                type:REGISTER_REFFERAL_ERROR,
                payload:{msg:error.response.data.msg}
            })
        }
        clearAlert()
     }
     const loginUser = async(currentUser)=>{
        dispatch({type:LOGIN_USER_BEGIN})
        try {
            const {data} = await axios.post('/api/v1/auth/login',currentUser)
        
            const {user,token,role} = data
            dispatch({
                type:LOGIN_USER_SUCCESS,
                payload:{user,token,role}
            })
            addUserToLocalStorage({user,token,role})
            // local storage later
        } catch (error) {
            console.log(error.response)
            dispatch({
                type:LOGIN_USER_ERROR,
                payload:{msg:error.response.data.msg}
            })
        }
        clearAlert()
     }
     const RefferalloginUser = async(currentUser)=>{
        dispatch({type:REFFERAL_LOGIN_USER_BEGIN})
        try {
            const {data} = await axios.post('/api/v1/auth/login-refferal',currentUser)
        
            const {user,token,role} = data
            dispatch({
                type:REFFERAL_LOGIN_USER_SUCCESS,
                payload:{user,token,role}
            })
            console.log(user,"useralksjdfkjaskjdfkjaksjdfkjaksdjfkl")
            addUserToLocalStorage({user,token,role,email:user.email})
            // local storage later
        } catch (error) {
            console.log(error.response)
            dispatch({
                type:REFFERAL_LOGIN_USER_ERROR,
                payload:{msg:error.response.data.msg}
            })
        }
        clearAlert()
     }
     const bdmLoginUser = async(currentUser)=>{
        dispatch({type:REFFERAL_LOGIN_USER_BEGIN})
        try {
            const {data} = await axios.post('/api/v1/auth/login-bdm',currentUser)
        
            const {user,token,role} = data
            dispatch({
                type:REFFERAL_LOGIN_USER_SUCCESS,
                payload:{user,token,role}
            })
            addUserToLocalStorage({user,token,role})
            // local storage later
        } catch (error) {
            console.log(error.response)
            dispatch({
                type:REFFERAL_LOGIN_USER_ERROR,
                payload:{msg:error.response.data.msg}
            })
        }
        clearAlert()
     }
    //  const navigate = useNavigate()
     const logout = (e)=>{
        removeUserFromLocalStorage()
        

     }
     return <AppContext.Provider value={{...state,displayAlert,registerUser,loginUser,logout,RefferalloginUser,bdmLoginUser}}>
        {children}
    </AppContext.Provider>
}

const useAppContext = ()=>{
    return useContext(AppContext)
}
export {AppProvider,initialState,useAppContext}