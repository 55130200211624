import React, { useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import Menu from './Menu'
// import React from 'react'
import { useAppContext } from './context/appContext'
import StudentList from './components/refferal/StudentList'
import RefferalClientDashboard from './components/refferal/RefferalClientDashboard'
import axios from 'axios'
import RefferalUserListTable from './components/refferal/RefferalUserListTable'
import YoutuberRefferal from './pages/YoutuberRefferal'
import BdmRefferal from './pages/BdmRefferal'
function Dashboard() {
  const { user } = useAppContext()
  const navigate = useNavigate()
  const [mbaStudentsData, setMbaStudents] = useState({})
  const [enggStudentsData, setEnggStudents] = useState({})
  const [pcmStudentsData, setPcmStudents] = useState({})
  const [pcbStudentsData, setPcbStudents] = useState({})
  const [otherStudentsData, setOtherStudents] = useState({})
  const [regStudentsData, setRegStudents] = useState({})
  const [unverifiedStud, setunverifiedStud] = useState({})

  const mbaStudents = async () => {
    fetch('/mba-students')
      .then(response => response.json())
      .then(data => {
        setMbaStudents(data)
        
        
      })
      .catch(error => {
        console.log(error)
      })
  }
  const enggStudents = async () => {
    fetch('/engg-students')
      .then(response => response.json())
      .then(data => {
        setEnggStudents(data) 
        
        
      })
      .catch(error => {
        console.log(error)
      })
  }
  const pcmStudents = async () => {
    fetch('/pcm-students')
      .then(response => response.json())
      .then(data => {
        setPcmStudents(data) 
        
        
      })
      .catch(error => {
        console.log(error)
      })
  }
  const pcbStudents = async () => {
    fetch('/pcb-students')
      .then(response => response.json())
      .then(data => {
        setPcbStudents(data) 
        
        
      })
      .catch(error => {
        console.log(error)
      })
  }
  const otherStudents = async () => {
    fetch('/other-students')
      .then(response => response.json())
      .then(data => {
        setOtherStudents(data) 
        
        
      })
      .catch(error => {
        console.log(error)
      })
  }
  const regStudents = async () => {
    fetch('/reg-students')
      .then(response => response.json())
      .then(data => {
        setRegStudents(data) 
        
        
      })
      .catch(error => {
        console.log(error)
      })
  }
  const unverifiedStudents = async () => {
    fetch('/user-list-unverified')
      .then(response => response.json())
      .then(data => {
        setunverifiedStud(data) 
        
        
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    mbaStudents()
    enggStudents()
    otherStudents()
    pcbStudents()
    pcmStudents()
    regStudents()
    unverifiedStudents()
  }, [])

  const [mbaPartial, setMbaPartial] = useState([])
  const [enggPartial, setEnggPartial] = useState([])
  const [pcmPartial, setPcmPartial] = useState([])
  const [pcbPartial, setPcbPartial] = useState([])
  const [otherPartial, setOtherPartial] = useState([])
  console.log(mbaPartial)
    const mbaUrl = '/mba-students-partial'
    const enggUrl = '/engg-students-partial'
    const pcmUrl = '/pcm-students-partial'
    const pcbUrl = '/pcb-students-partial'
    const otherUrl = '/Other-students-partial'
    const [application,setApplication] = React.useState()
    const [partial,setPartial] = React.useState()
    React.useEffect(()=>{
      // axios.get(mbaUrl).then((response)=>{
      //   setMbaPartial(response.data)
      // })
      // axios.get(enggUrl).then((response)=>{
      //   setEnggPartial(response.data)
      // })
      // axios.get(pcmUrl).then((response)=>{
      //   setPcmPartial(response.data)
      // })
      // axios.get(pcbUrl).then((response)=>{
      //   setPcbPartial(response.data)
      // })
      // axios.get(otherUrl).then((response)=>{
      //   setOtherPartial(response.data)
      // })
      axios.get('/all-applications').then((response)=>{
        setApplication(response.data.data)
      })
      axios.get('/all-partial-applications').then((response)=>{
        setPartial(response.data.data[0].total)
        console.log(response.data.data,"partial student")
      })

    },[])
  return (
    <div>
      <Header/>
      <Menu/>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          {
            user ? <>
              {
                user.role === "Admin" ? 
                  <>
                  <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">AICEE - Application - 2023</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}
          </div>
          {/* /.content-header */}
          {/* Main content */}
          <div className="col-md-12 " >
            <div className="row ">
              <div className="col-xl-3 col-lg-6">
                <div className="card l-bg-cherry">
                  <div className="card-statistic-3 p-4" style={{cursor:'pointer'}} onClick={()=>{navigate('/mba-dashboard')}}>
                    <div className="card-icon card-icon-large" style={{cursor:'pointer'}}>
                    <i className="fas fa-users" style={{cursor:'pointer'}}/>
                    </div>
                    <div className="mb-4">
                      <h5 className="card-title mb-0">MBA / PGDM</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                      <div className="col-8">
                        <h2 className="d-flex align-items-center mb-0">{application && application.mba}</h2>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          <i className="fa fa-arrow-up" />
                        </span>
                      </div>
                    </div>
                    <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                      <div
                        className="progress-bar l-bg-cyan"
                        role="progressbar"
                        data-width="25%"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card l-bg-blue-dark" style={{cursor:'pointer'}}>
                  <div className="card-statistic-3 p-4" onClick={()=>{navigate('/Engg-dashboard')}}>
                    <div className="card-icon card-icon-large">
                      <i className="fas fa-users" />
                    </div>
                    <div className="mb-4">
                      <h5 className="card-title mb-0">Engineering</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                      <div className="col-8">
                        <h2 className="d-flex align-items-center mb-0">{application && application.engg}</h2>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          <i className="fa fa-arrow-up" />
                        </span>
                      </div>
                    </div>
                    <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                      <div
                        className="progress-bar l-bg-green"
                        role="progressbar"
                        data-width="25%"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card l-bg-green-dark" style={{cursor:'pointer'}}>
                  <div className="card-statistic-3 p-4" onClick={()=>{navigate('/PCM-dashboard')}}>
                    <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                    </div>
                    <div className="mb-4">
                      <h5 className="card-title mb-0">PCM</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                      <div className="col-8">
                        <h2 className="d-flex align-items-center mb-0">{application && application.pcm}</h2>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          <i className="fa fa-arrow-up" />
                        </span>
                      </div>
                    </div>
                    <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                      <div
                        className="progress-bar l-bg-orange"
                        role="progressbar"
                        data-width="25%"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card l-bg-orange-dark" style={{cursor:'pointer'}}>
                  <div className="card-statistic-3 p-4" onClick={()=>{navigate('/PCB-dashboard')}}>
                    <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                    </div>
                    <div className="mb-4">
                      <h5 className="card-title mb-0">PCB</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                      <div className="col-8">
                        <h2 className="d-flex align-items-center mb-0">{application && application.pcb}</h2>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          <i className="fa fa-arrow-up" />
                        </span>
                      </div>
                    </div>
                    <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                      <div
                        className="progress-bar l-bg-cyan"
                        role="progressbar"
                        data-width="25%"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card l-bg-sky-dark" style={{cursor:'pointer'}}>
                  <div className="card-statistic-3 p-4" onClick={()=>{navigate('/other-dashboard')}}>
                    <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                    </div>
                    <div className="mb-4">
                      <h5 className="card-title mb-0">Other</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                      <div className="col-8">
                        <h2 className="d-flex align-items-center mb-0">{application && application.other}</h2>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          <i className="fa fa-arrow-up" />
                        </span>
                      </div>
                    </div>
                    <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                      <div
                        className="progress-bar l-bg-cyan"
                        role="progressbar"
                        data-width="25%"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card l-bg-reg-dark" style={{cursor:'pointer'}}>
                  <div className="card-statistic-3 p-4" onClick={()=>{navigate('/registerd-student')}}>
                    <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                    </div>
                    <div className="mb-4">
                      <h5 className="card-title mb-0">Total Registered Students</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                      <div className="col-8">
                        <h2 className="d-flex align-items-center mb-0">{application && application.totalReg}</h2>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          <i className="fa fa-arrow-up" />
                        </span>
                      </div>
                    </div>
                    <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                      <div
                        className="progress-bar l-bg-cyan"
                        role="progressbar"
                        data-width="25%"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card l-bg-reg-dark" style={{cursor:'pointer'}}>
                  <div className="card-statistic-3 p-4" onClick={()=>{navigate(`/total-partial-student/`)}}>
                    <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                    </div>
                    <div className="mb-4">
                      <h5 className="card-title mb-0">Partial Applications</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                      <div className="col-8">
                        <h2 className="d-flex align-items-center mb-0">{partial && partial}</h2>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          <i className="fa fa-arrow-up" />
                        </span>
                      </div>
                    </div>
                    <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                      <div
                        className="progress-bar l-bg-cyan"
                        role="progressbar"
                        data-width="25%"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "75%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="card l-bg-reg-red" style={{cursor:'pointer'}}>
                  <div className="card-statistic-3 p-4" onClick={()=>{navigate(`/total-unverified-student`)}}>
                    <div className="card-icon card-icon-large">
                    <i className="fas fa-users" />
                    </div>
                    <div className="mb-4">
                      <h5 className="card-title mb-0">Total Unknown Students</h5>
                    </div>
                    <div className="row align-items-center mb-2 d-flex">
                      <div className="col-8">
                        <h2 className="d-flex align-items-center mb-0">{application && application.Unknownstudent}</h2>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          <i className="fa fa-arrow-up" />
                        </span>
                      </div>
                    </div>
                    <div className="progress mt-1 " data-height={8} style={{ height: 8 }}>
                      <div
                        className="progress-bar l-bg-cyan"
                        role="progressbar"
                        data-width="25%"
                        aria-valuenow={25}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: "75%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                  </>:
                  <>
                  {
                    user.role === "bdm" ? <>
                      <BdmRefferal/>
                    </>:<>
                      <YoutuberRefferal user={user}/>
                    </>
                  }

                  </>
                  
                
              }
            </>:null
          }

          {/* /.content */}
        </div>
      </div>
  )
}

export default Dashboard

// export default class Dashboard extends Component {
//   render() {
//     // const navigate = useNavigate()
//     return (
      

//     )
//   }
// }
