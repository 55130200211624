import React, { useState } from 'react'
import { FormInput, Header, Menu, Alert, FormSelect } from '../Index'
import {useAppContext} from '../../context/appContext'
import {useNavigate} from 'react-router-dom'
const initialState = {
    FullName: '',
    emailId: '',
    role: '',
    mobileNo: '',
    password: '',
    accountHolderName:'',
    accountNumber:'',
    bankName:'',
    branchName:'',
    address:'',
    ifscCode:'',
    refferalCode:''
}
function AddUser() {
    const navigate = useNavigate()
    const [value, setValue] = useState(initialState)
    const {user,isLoading,showAlert,displayAlert,registerUser} = useAppContext()
   
    // console.log(user,isLoading,showAlert,displayAlert)
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    
    const onSubmit = async(e) => {
        e.preventDefault()
        const { FullName, emailId, role, mobileNo,password,accountNumber,accountHolderName,bankName,branchName,address,ifscCode,refferalCode} = value
        if (!FullName || !emailId || !role || !mobileNo || !password || !accountNumber || !accountHolderName || !bankName || !branchName || !address || !ifscCode || !refferalCode) {
            displayAlert()
            return
        }
        const currentUser = { FullName, emailId, role, mobileNo,password,accountNumber,accountHolderName,bankName,branchName,address,ifscCode,refferalCode,createdBy:user.email }
        await registerUser(currentUser)
        // setValue(initialState)
        // navigate('/referal/link')
    }
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">
            <h1 className='text-center underline' style={{textDecoration:"underline"}}>Add Refferal User and create Link</h1>
                <form action="" className='form-group p-2' onSubmit={onSubmit}>
                    <div className=''>
                        {showAlert && (<Alert/>)}
                    </div>
                    <div className='row'>
                        <div className="col">
                            <FormInput label="Full Name" placeholder="Enter Full Name" type="text" name='FullName' value={value.FullName} handleChange={handleChange} />
                        </div>
                        <div className="col">
                            <FormInput label="Email Id" placeholder="Enter Email Id" type="email" name='emailId' value={value.emailId} handleChange={handleChange} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col">
                            <FormInput label="Mobile No" placeholder="Enter  Mobile No" type="text" name='mobileNo' value={value.mobileNo} handleChange={handleChange} />
                        </div>
                        <div className="col">
                            <FormInput label="Refferal Code" placeholder="Enter Unique Refferal Code" type="text" name='refferalCode' value={value.refferalCode} handleChange={handleChange} />
                        </div>
                        <div className="col">
                            <FormSelect label="Refferal Role" placeholder="Enter Full Name" type="text" name='role' value={value.role} handleChange={handleChange} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col">
                            <FormInput label="Account Holder Name" placeholder="Enter Account Holder Name" type="text" name='accountHolderName' value={value.accountHolderName} handleChange={handleChange} />
                        </div>
                        <div className="col">
                            <FormInput label="Account Number" placeholder="Enter  Account No" type="text" name='accountNumber' value={value.accountNumber} handleChange={handleChange} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col">
                            <FormInput label="Bank Name" placeholder="Enter Bank Name" type="text" name='bankName' value={value.bankName} handleChange={handleChange} />
                        </div>
                        <div className="col">
                            <FormInput label="IFSC Code" placeholder="Enter  IFSC Code" type="text" name='ifscCode' value={value.ifscCode} handleChange={handleChange} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col">
                            <FormInput label="Branch Name" placeholder="Enter Bank Branch Name" type="text" name='branchName' value={value.branchName} handleChange={handleChange} />
                        </div>
                        <div className="col">
                            <FormInput label="Create Password" placeholder="Create Password" type="password" name='password' value={value.password} handleChange={handleChange} />
                        </div>
                        
                    </div>
                    <div className='row'>
                    <div className="col">
                            <FormInput label="Address" placeholder="Enter  Address" type="text" name='address' value={value.address} handleChange={handleChange} />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <center><button disabled={isLoading} type="submit" class="btn btn-primary">Add Refferal User</button></center>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default AddUser