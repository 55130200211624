import React from 'react'

function FormSelect({label,handleChange,name,value}) {
  return (
    <div>
        <label for="exampleFormControlSelect1">{label}</label>
    <select onChange={handleChange} name={name} value={value} class="form-control" id="exampleFormControlSelect1">
    <option value="">Refferal Role</option>
      <option value="Teacher's">Teacher's</option>
      <option value="Youtuber">Youtuber</option>
      <option value="Other">Other</option>
    </select>
    </div>
  )
}

export default FormSelect