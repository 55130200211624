import React, { Component, useState, useEffect } from 'react'
import Header from '../Header'
import Menu from '../Menu'

// import CompletedApplication from './CompletedApplication'
// import TotalApplication from './TotalApplication'
// import CB from './CB'
// import HB from './HB'
import RegisteredStudent from './RegisteredStudent'
function RegDashboard() {
    const [filter, setFilter] = useState('Partial Application Filled Data')
    const [mbaStudentsPartial, setMbaStudents] = useState({})
    const [mbaStudentsCompletedAppl, setMbaStudentsCompleted] = useState({})
    const [cbe, setCbe] = useState({})
    const [hbe, setHbe] = useState({})
    // console.log(mbaStudentsPartial)
    const mbaStudents = async () => {
        fetch('/pcm-students-partial')
            .then(response => response.json())
            .then(data => {
                setMbaStudents(data)


            })
            .catch(error => {
                console.log(error)
            })
    }
    const mbaStudentsCompleted = async () => {
        fetch('/pcm-students-completed')
            .then(response => response.json())
            .then(data => {
                setMbaStudentsCompleted(data)


            })
            .catch(error => {
                console.log(error)
            })
    }
    const mbaStudentsHBE = async () => {
        fetch('/pcm-students-home-based')
            .then(response => response.json())
            .then(data => {
                setHbe(data)


            })
            .catch(error => {
                console.log(error)
            })
    }
    const mbaStudentsCBE = async () => {
        fetch('/pcm-students-center-based')
            .then(response => response.json())
            .then(data => {
                setCbe(data)


            })
            .catch(error => {
                console.log(error)
            })
    }
    useEffect(() => {
        mbaStudents()
        mbaStudentsCompleted()
        mbaStudentsCBE()
        mbaStudentsHBE()
    }, [])
    return (
        <div>
        <Header/>
    <Menu/>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Total Registered Student</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard v1</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div className="row">
                            
                           
                           
                           
                        </div>
                       
                        {
                            filter === "Partial Application Filled Data" ? <><RegisteredStudent />
                            </> :
                                <></>
                        }


                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
        </div>
    )
}

export default RegDashboard
