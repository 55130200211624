import React, { Component, useState, useEffect } from 'react'
import Header from '../Header';
import Menu from '../Menu';
import 'jquery/dist/jquery.min.js';
// //Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
function SeatConfirm() {
    const navigate = useNavigate()
    const [data, setState] = useState()
    console.log("data", data)
    // State array variable to save and show data
    const fetchData = () => {
        axios.get('/counselling/locked-college-list').then(res => {
            //Storing users detail in state array object
            setState({ data: res.data.data });
            // console.log("dataAPI", res.data.userList);

        });
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable(
                    {
                        pagingType: 'full_numbers',
                        pageLength: 15,
                        processing: true,
                        dom: 'Bfrtip',
                        buttons: ['copy', 'csv', 'print', 'excelHtml5', 'pdf'
                        ]
                    }
                );
            },
                1000
            );
        });
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">

                <div className="container p-5">

                    <table id="example" class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Sl.No</th>

                                <th>Student Name</th>

                                <th>Mobile No</th>
                                <th>Email-ID</th>

                                <th>Program</th>


                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.data.map((result, slNo) => {
                                return (

                                    <tr>
                                        <td>{slNo + 1}</td>
                                        <td>{result.user.name}</td>
                                        <td>{result.user.mobileNo}</td>
                                        <td>{result.user.email}</td>
                                        <td>{result.user.program}</td>

                                        <td><Link style={{ background: 'green', border: 'none', border: '1px solid gray', borderRadius: '20px', fontSize: '15px', padding: '5px 15px', color: 'white' }} to={`/counselling/dashboard/seat-confirm/${result.user._id}`} target='__blank'>View</Link></td>
                                    </tr>

                                )
                            })}


                        </tbody>
                    </table>

                </div>

            </div>
        </div>
    )
}

export default SeatConfirm
