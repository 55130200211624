import React, { useState } from 'react'
import axios from 'axios'
const CollegeList = ({ college, studentId, fetchData, setIsOpen, roundOneCollege, round, requestType }) => {

    const [showModal, setShowModal] = useState(false);
    const [selectedCollege, setSelectedCollege] = useState(null);


    const allot_college = async (college_name, college_code, college_program, college_state, college_branch) => {



        if (window.confirm(`Are you sure you want to allot this ${college_name} college`)) {
            await axios.post(`/allot-college-round/${round}/${studentId}`, {
                college_name,
                college_code,
                college_program,
                college_state,
                college_branch
            })
                .then((res) => {
                    fetchData()
                    setIsOpen(false)
                    alert('college Allot Successfully')
                }).catch((err) => {
                    alert('Something Went Wrong')
                })
        }
        else {

        }
        setShowModal(true);

    }

    const handleConfirm = () => {
        // Handle confirmation logic here
        alert('You clicked OK!');
        closeModal();
    };

    const handleCancel = () => {
        // Handle cancellation logic here
        alert('You clicked Cancel or closed the dialog.');
        closeModal();
    };

    const closeModal = () => {
        setShowModal(false);
    };
    return (
        <div>
            <div class="table-responsive">
                <table class="table">

                    <thead>
                        <tr style={{ fontSize: '12px' }}>
                            <th scope="col">Sl</th>
                            <th scope="col">College Name</th>
                            <th scope="col">Program</th>
                            {
                                college[0].branch && <th scope="col">Branch</th>
                            }
                            <th scope="col">State</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            college && college.map((e, i) => {
                                console.log(i, e)
                                {/* const data = {
                                    collegeName:e.collegeName,
                                    college_code:e.collegeCode,
                                    college_program:e.programme,
                                    college_state:e.state,
                                    college_branch:e.branch,
                                    one:'one'
                                } */}
                                return (
                                    <>
                                        {
                                            roundOneCollege ? <>
                                                {roundOneCollege.college_code == e.collegeCode ?
                                                    <tr style={{ fontSize: '10px', backgroundColor: 'blue', color: 'white' }} >
                                                        <th scope="row" style={{ padding: '10px' }}>{i + 1}</th>
                                                        <td style={{ padding: '10px' }}>{e.collegeName}</td>
                                                        <td style={{ padding: '10px' }}>{e.programme}</td>
                                                        {
                                                            e.branch && <td style={{ padding: '10px' }}>{e.branch}</td>
                                                        }
                                                        <td style={{ padding: '10px' }}>{e.state}</td>
                                                        <td style={{ padding: '10px', backgroundColor: 'green', color: 'white', textAlign: 'center', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer' }} onClick={() => { allot_college(e.collegeName, e.collegeCode, e.programme, e.state, e.branch,) }}>Allot</td>
                                                    </tr> : <tr style={{ fontSize: '10px', }} >
                                                        <th scope="row" style={{ padding: '10px' }}>{i + 1}</th>
                                                        <td style={{ padding: '10px' }}>{e.collegeName}</td>
                                                        <td style={{ padding: '10px' }}>{e.programme}</td>
                                                        {
                                                            e.branch && <td style={{ padding: '10px' }}>{e.branch}</td>
                                                        }
                                                        <td style={{ padding: '10px' }}>{e.state}</td>
                                                        <td style={{ padding: '10px', backgroundColor: 'green', color: 'white', textAlign: 'center', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer' }} onClick={() => { allot_college(e.collegeName, e.collegeCode, e.programme, e.state, e.branch,) }}>Allot</td>
                                                    </tr>
                                                }
                                            </> : <>
                                                <tr style={{ fontSize: '10px' }}>
                                                    <th scope="row" style={{ padding: '10px' }}>{i + 1}</th>
                                                    <td style={{ padding: '10px' }}>{e.collegeName}</td>
                                                    <td style={{ padding: '10px' }}>{e.programme}</td>
                                                    {
                                                        e.branch && <td style={{ padding: '10px' }}>{e.branch}</td>
                                                    }
                                                    <td style={{ padding: '10px' }}>{e.state}</td>
                                                    <td style={{ padding: '10px', backgroundColor: 'green', color: 'white', textAlign: 'center', fontWeight: 'bold', borderRadius: '5px', cursor: 'pointer' }} onClick={() => { allot_college(e.collegeName, e.collegeCode, e.programme, e.state, e.branch,) }}>Allot</td>
                                                </tr>
                                            </>
                                        }


                                    </>
                                )
                            })


                        }
                    </tbody>
                </table>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Are you sure you want to proceed?</p>
                        <button onClick={handleConfirm}>OK</button>
                        <button onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CollegeList