import React, { useState } from 'react'
import { FormInput, Header, Menu, Alert, FormSelect } from '../Index'
import {useAppContext} from '../../context/appContext'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
const initialState = {
    FullName: '',
    emailId: '',
    role: '',
    mobileNo: '',
    password: '',
}
function Register() {
    const navigate = useNavigate()
    const [value, setValue] = useState(initialState)
    const {user,isLoading,showAlert,displayAlert,registerUser} = useAppContext()
    console.log(value)
    // console.log(user,isLoading,showAlert,displayAlert)
    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    
    const onSubmit = async(e)=>{
        e.preventDefault()
        axios.post('/api/v1/auth/bdm/register',{name:value.FullName,email:value.emailId,mobileNo:value.mobileNo,password:value.password})
        .then((res)=>{
            alert(res.data.msg)
            setValue(initialState)
            getBdm()
        })
        .catch((err)=>{
            console.log(err)
            alert(err.response.data.msg)
        })
    }

    const [bdm,setBdm] = useState()
    console.log(bdm)
    const getBdm = async () => {
        fetch(`/total-bdm`)
          .then(response => response.json())
          .then(data => {
            setBdm(data.data)
          })
          .catch(error => {
            console.log(error)
          })
      }
    React.useEffect(()=>{
        setValue(initialState)
        getBdm()
    },[])
    return (
        <div>
            <Header />
            <Menu />
            <div className="content-wrapper">
            <h1 className='text-center underline' style={{textDecoration:"underline"}}>ADD BDM</h1>
                <form action="" className='form-group p-2' onSubmit={onSubmit}>
                    <div className=''>
                        {showAlert && (<Alert/>)}
                    </div>
                    <div className='row'>
                        <div className="col">
                            <FormInput label="Full Name" placeholder="Enter Full Name" type="text" name='FullName' value={value.FullName} handleChange={handleChange} />
                        </div>
                        <div className="col">
                            <FormInput label="Email Id" placeholder="Enter Email Id" type="email" name='emailId' value={value.emailId} handleChange={handleChange} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col">
                            <FormInput label="Mobile No" placeholder="Enter  Mobile No" type="text" name='mobileNo' value={value.mobileNo} handleChange={handleChange} />
                        </div>
                        <div className="col">
                            <FormInput label="Create Password" placeholder="Create Password" type="password" name='password' value={value.password} handleChange={handleChange} />
                        </div>
                    </div>
                    
                    <div className='mt-3'>
                        <center><button disabled={isLoading} type="submit" class="btn btn-primary">Add BDM</button></center>
                    </div>
                </form>
                <div className='p-2'>
              <div class="table-responsive">
                <table class="table table-bordered table-dark">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">mobileNo</th>
                      <th scope="col">Username</th>
                      <th scope="col">Password</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    
                  {
                      bdm && bdm.map((e, i) => {
                        return (
                          <tr>
                            <th scope="row">{i + 1}</th>
                            <td>{e.name}</td>
                            <td>{e.mobileNo}</td>
                            <td>{e.email}</td>
                            <td>{e.password}</td>
                          
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>
            </div>

        </div>
    )
}

export default Register