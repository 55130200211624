import React, { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useAppContext } from './context/appContext'


function Header() {
  const navigate = useNavigate()

  const { user, logout,token } = useAppContext()
  const adminAuthenticate = async () => {
    fetch('/authorization-admin')
      .then(response => response.json())
      .then(data => {
        // console.log(data)

      })
      .catch(error => {
        navigate('/login')
      })
  }
  //   useEffect(() => {
  //     adminAuthenticate()

  // }, [])
  const logouts = () => {
    logout()
    // localStorage.removeItem('token')
    // localStorage.removeItem('user')
    // localStorage.removeItem('role')
    // console.log('hello world')
    if(!token){
      // console.log(token)
      // console.log("logout")
      setTimeout(()=>{
        navigate('/')
      },1)
    }
  }

  return (
    <div>
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a href="/" className="nav-link">Home</a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              {/* <Link to="/logout" className="nav-link">Logout</Link> */}
              <Link to='' onClick={logouts} className="nav-link">Logout</Link>
            </li>
          </ul>
          {/* SEARCH FORM */}
          <form className="form-inline ml-3">

          </form>
          {/* Right navbar links */}
          <ul className="navbar-nav ml-auto">
            {/* Messages Dropdown Menu */}

            {/* Notifications Dropdown Menu */}

            <li className="nav-item">

            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Header